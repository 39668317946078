import React from "react"

import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1>NOT FOUND</h1>
    <p>
      The page you are attempting to visit does not exist.
    </p>
    <Link className="btn btn-primary" to="/">
      Home
    </Link>
  </Layout>
)

export default NotFoundPage
